import React from "react"
import LayoutA3 from "../../components/layout-a3"
import raceInfoStyles from './a3_raceInfo.module.css'

export default class A3RaceInfo extends React.Component {
  render() {
    console.log("this.props:",this.props)
    const infotype = this.props.pageContext.element.infotype
    console.log("infotype",infotype)
    const topTitle=infotype==='rules'?'竞赛规程':'赛事路线'
    const raceName='2019簇格马拉松竞赛规程'
    const contents=this.props.pageContext.element.contents

    const contentlist=contents.map((content,index)=>
      <div key={index} className={raceInfoStyles.content}>
        <p>{content.title}</p>
        {content.content.map((item,index)=>
          <div key={index}>
            {item.type==='text'&&<p key={index}>{item.text}</p>}
            {item.type==='img'&&<img alt="hello" key={index}  src={item.src}/>}
          </div>

        )}
      </div>)
    return(
      <LayoutA3>
        <div className={raceInfoStyles.container}>
          <img className={raceInfoStyles.raceIcon} src={require('../../images/a3/race.png')}/>
          <div className={raceInfoStyles.contentContainer}>
            <div className={raceInfoStyles.rules}>
              <h4>{topTitle}</h4>
              <p className={raceInfoStyles.raceName}>{raceName}</p>
              <div>{contentlist}</div>
            </div>
          </div>
        </div>
      </LayoutA3>
    )
  }
}